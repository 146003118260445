<template>
  <div class="product">
    <el-table :data="tableData" header-cell-class-name="tableHeader" style="width: 100%" @row-dblclick="seeDetails">
      <el-table-column label="序号" type="index" :index="getIndex" width="100" />
      <el-table-column prop="productName" label="物料名称" min-width="120" />
      <el-table-column prop="materialNo" label="物料编码" min-width="120" />
      <el-table-column prop="partCode" label="图号" min-width="120" />
      <el-table-column prop="versionCode" label="版本号" min-width="120" />
      <el-table-column prop="customerName" label="客户" min-width="120" />
      <el-table-column prop="rawMaterial" label="材质" min-width="120" />
      <el-table-column prop="shape" label="毛坯形状" min-width="120" />
      <el-table-column prop="diameter" label="毛坯直径(mm)" min-width="120" />
      <el-table-column prop="length" label="毛坯长(mm)" min-width="120" />
      <el-table-column prop="width" label="毛坯宽(mm)" min-width="120" />
      <el-table-column prop="height" label="毛坯高(mm)" min-width="120" />
      <el-table-column prop="blankWeight" label="毛坯重量(kg)" min-width="120" />
      <el-table-column prop="productWeight" label="成品重量(kg)" min-width="120" />
      <el-table-column prop="lossRate" label="损耗率" min-width="120" />
      <el-table-column label="操作" fixed="right" min-width="120" align="center">
        <template slot-scope="scope">
          <span style="color: #2DEB79;cursor:pointer" @click="seeDetails(scope.row)">查看工序</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="page" :size.sync="size" @getTableData="handleSearch" />
  </div>
</template>

<script>
import {getProductInfo} from '@/api/data.js'
import pagination from '@/components/pagination'
export default {
  name: 'product',
  props:{
    searchValue:String
  },
  data() {
    return {
      tableData:[],
      total:0,
      page:1,
      size:10,
    }
  },
  components: {
    pagination
  },
  watch: {
    size(){
      this.handleSearch(1)
    },
  },
  mounted() { },
  methods: {
    getIndex(index) {
      const result = (this.page- 1) * this.size + index + 1
      return result < 10 ? `0${result}` : result
    },
    handleSearch(page){
      page && (this.page = page);
      let params = {
        page:this.page,
        size:this.size,
        searchValue:this.searchValue
      }
      getProductInfo(params).then(res=>{
        if(res.code === 0){
          this.tableData = res.data.records
          this.total = res.data.total
        }else{
          this.$message({
            message:res.msg || '接口出错',
            showClose: true,
            duration: 0,
            type:'error'
          })
        }
      }).catch(()=>{
        this.$message({
          message:'接口出错',
          showClose: true,
          duration: 0,
          type:'error'
        })
      })
    },
    seeDetails(row) {
      this.$router.push({
        path: 'editproductData',
        query: {
          partCode: row.partCode,
          partVersion: row.versionCode,
          id: row.id,
          ltitle: '查看工序'
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.product {}
</style>

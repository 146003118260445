<template>
  <div class="productData dataM">
    <div class="tabData">
      <div class="tool">
        <upload 
          ref="upload"
          v-premiSub="'产品数据_导入'"
          :templateName="templateName[activeTab]"
          :templateParam="templateParam[activeTab]" 
          @handleSearch="handleSearch(0)" 
        />
        <el-input 
          v-model="searchValues[activeTab]" 
          style="width:220px" size="small" 
          @keydown.enter.native="handleSearch()"
          :placeholder="placeholder[activeTab]"
          v-premiSub="'产品数据_搜索'"
        >
          <i slot="suffix" class="el-input__icon el-icon-search" @click="handleSearch()"></i>
        </el-input>
      </div>
      <el-tabs v-model="activeTab">
        <!-- 产品工艺 -->
        <el-tab-pane label="产品工艺" name="0" class="BOMContent">
          <product ref="product" :searchValue="searchValues[activeTab]"/>
        </el-tab-pane>
        <!-- 装配工艺 -->
        <el-tab-pane label="装配工艺" name="1" class="BOMContent">
          <assembly ref="assembly" :searchValue="searchValues[activeTab]"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import product from './components/product.vue'
import assembly from './components/assembly.vue'
import upload from '@/components/upload/upload'
export default {
  name: "produceResource",
  components: {
    product,
    assembly,
    upload
  },
  data() {
    return {
      activeTab: '0',
      templateName: ['产品工艺导入模板', '装配工艺导入模板'],
      templateParam:['productInfo','assembleProcess'],
      placeholder:['请输入物料编码/名称/图号','请输入物料编码/名称/图号'],
      searchValues:['','']
    }
  },
  computed: {

  },
  watch: {
    
  },
  activated(){
    this.$refs.product.handleSearch()
    this.$refs.assembly.handleSearch()
  },
  methods: {
    handleSearch(key) {
      if (this.activeTab == 0) {
        // 产品BOM
        this.$refs.product.handleSearch(key)
      } else {
        // 装配BOM
        this.$refs.assembly.handleSearch(key)
      }
    }
  }
}
</script>

<style lang="scss">
.productData {
  text-align: left;
  color: #E8E8E8;
  margin-top: -5px;
  padding: 0px 2px;

  img {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  .tabData {
    margin: 16px 30px 0px;
    padding: 20px 32px 0px 32px;
    background: #232323;
    border-radius: 8px;
    min-height: calc(100vh - 200px);
    position: relative;

    .tool {
      position: absolute;
      right: 30px;
      display: flex;
      align-items: center;
      z-index: 9;

      img {
        margin-right: 30px;
      }
    }
    .BOMContent{
      position: relative;
      min-height: calc(100vh - 320px);
      padding-bottom: 80px;
    }
  }
}
</style>
<template>
  <div class="upload-demo">
    <!-- <el-upload
      class="upload-demo"
      action=""
      :show-file-list="false"
      accept=".xlsx"
      :headers="{'Content-Type':'multipart/form-data'}"
      :on-change = "uploadFile"
      :http-request = "httpRequest"
      ref = "uploadFile"
    >
      <el-tooltip effect="dark" content="导入" placement="top">
        <img src="@/assets/images/dataManage/import.png"/>
      </el-tooltip>
    </el-upload> -->
    <el-tooltip effect="dark" content="导入" placement="top">
      <img src="@/assets/images/dataManage/import.png" @click="importVisible=true"/>
    </el-tooltip>
    <!-- 导入弹框 -->
    <import-dialog 
      v-if="importVisible" 
      :visible.sync="importVisible"
      :templateName="templateName"
      :templateParam="templateParam"
      :apis="apis"
      @success="success"
    />
  </div>
</template>

<script>
import ImportDialog from '@/components/dialog/ImportDialog.vue'
import { importCustomer,importEquipment,importProduct,importTechnology,importProductBom,assembleProcess,importStaff } from '@/api/data.js'
export default {
  name:'upload',
  components:{
    ImportDialog
  },
  props:{
    templateName:{
      default:'生产设备导入模板'
    },
    templateParam:{
      default:'productionEquipment'
    },
  },
  data(){
    return{
      importVisible:false,
      apis:{
        import:'',//导入请求的接口
      },
      importType:{
        productionEquipment:importEquipment,
        customerInfo:importCustomer,
        productInfo:importProduct,
        processList:importTechnology,
        productBomInfo:importProductBom,
        assembleProcess:assembleProcess,
        staffInfo:importStaff
      }
    }
  },
  watch:{
    importVisible(val){
      if(val){
        this.apis.import = this.importType[this.templateParam]
        if(this.apis){
          console.error('apis属性未定义')
        }
      }
    }
  },
  methods:{
    httpRequest(){},
    uploadFile(file){
      this.$emit('uploadFile',file)
    },
    //重新上传
    reUpload(){
      // this.$refs.uploadFile.$refs['upload-inner'].$refs.input.click();
      this.importVisible =  true
    },
    success(){
      this.$emit('handleSearch')
    }
  }
}
</script>

<style scoped lang="scss">
.upload-demo{
  height: 22px;
  line-height: 1;
}
// ::v-deep .el-upload{
//   line-height: 1;
//   vertical-align: top;
//   img{
//     vertical-align: text-bottom;
//   }
// }
</style>